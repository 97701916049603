.footer-locations {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 50px;
    flex-wrap: wrap;
}

.footer-locations > a {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 30px;
}

@media screen and (max-width: 400px) {
    .footer-locations {
        flex-direction: column;
        align-items: center;
    }

    .footer-locations > a > img {
        width: 130px;
    }
}
