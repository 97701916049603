.footer {
    background: rgba(196, 196, 196, 0.2);
    width: 100%;
}

.footer__button {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
}

.footer > hr {
    max-width: 1076px;
}

@media screen and (max-width: 400px) {
    .footer {
        background: #fff;
    }
}