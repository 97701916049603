.youtube-header-text {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 41px;
    color: #F6AB1F;
    text-transform: uppercase;
    padding: 0 20px 10px 20px;
}

@media screen and (max-width: 575px) {
    .youtube-header-text {
        font-size: 26px;
    }
}
