
.youtube-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 15px;
    max-width: 78px;
    width: 100%;
}

.youtube-img-width {
    width: 100%;
    max-width: 215px;
    max-height: 215px;
}

.youtube-item > img {
    margin-bottom: 20px;
}

@media screen and (max-width: 1300px) {
    .youtube-item {
        padding: 0 20px;
    }
}

@media screen and (max-width: 406px) {
    .youtube-item {
        padding: 0 20px;
        max-width: calc(100% - 40px);
    }
}
